// CAROUSEL

$('#slider').owlCarousel({
    loop: true,
    autoplay: 1000,
    margin: 0,
    items: 1,
    lazyLoad: true,
    dots: false,
    nav:true,
    navText:false,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
            nav: true
        },
        600: {
            items: 1,
            nav: true
        },
        1000: {
            items: 1,
            nav: true,
            loop: true
        }
    }
});



$('#referenties').owlCarousel({
    loop: true,
    autoplay: 1000,
    margin: 0,
    items: 1,
    lazyLoad: true,
    dots: false,
    nav:true,
    navText:false,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
            nav: true
        },
        600: {
            items: 1,
            nav: true
        },
        1000: {
            items: 1,
            nav: true,
            loop: true
        }
    }
});